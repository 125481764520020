// require("@rails/ujs").start();
import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import Alpine from 'alpinejs'
// import 'jquery/src/jquery'
import 'bootstrap'

Rails.start()
ActiveStorage.start()

// console.log('10');
// console.log(jQuery);
// window.$ = $;
// window.jQuery = jQuery;
// window.jQuery = jQuery;
//
// require('../stylesheets/admin/application.scss');
//
// // ********** OLD **************
import TablePosition from '../src/admin/table_position'
import '../src/admin/custom'

window.TablePosition = TablePosition;

var jQueryBridget = require('jquery-bridget');
var Draggabilly = require('draggabilly');
jQueryBridget('draggabilly', Draggabilly, $);
var easyAutocomplete = require('easy-autocomplete');
var bootstrapToggle = require('bootstrap-toggle');

// import { pincodeInput } from 'bootstrap-pincode-input';
// import './admin/highlight'

//
// // // Support component names relative to this directory:
// var componentRequireContext = require.context("components", true);
// var ReactRailsUJS = require("react_ujs");
// ReactRailsUJS.useContext(componentRequireContext);
//
import ReactOnRails from 'react-on-rails';
// import Caterings from '../components/Caterings';
// import Records from '../components/Records';
import Customers from '../src/components/Customers';
// import CalculatorRow from '../components/CalculatorRow';
// import Calculator from '../components/Calculator';
// import Meal from '../components/Meal';
// import Ingredients from '../components/Ingredients';
// import Ingredient from '../components/Ingredient';
// import Deliveries from '../components/Deliveries';
// import DeliveryCustomer from '../components/DeliveryCustomer';
// import RoadTrip from '../components/RoadTrip';
// import RoadPlanner from '../components/RoadPlanner';
import summarizeCheckboxes from '../src/admin/summarize_checkboxes';
// import Chart from 'chart.js';
//
// // import Worksheet from '../components/Worksheet';
//
//
// // import TablePosition from './admin/table_position'
// // window.TablePosition = TablePosition;
//
// // css
// import 'fontawesome';
// import 'easy-autocomplete/dist/easy-autocomplete.css';
// require('../stylesheets/admin/application.scss');
// // import 'bootstrap-pincode-input/css/bootstrap-pincode-input.css';
// // import "tailwindcss/base";
// // import "tailwindcss/components";
// // import "tailwindcss/utilities";
//
ReactOnRails.register({
  // Caterings,
  // Records,
  Customers,
  // CalculatorRow,
  // Calculator,
  // Meal,
  // Ingredients,
  // Ingredient,
  // Deliveries,
  // DeliveryCustomer,
  // RoadTrip,
  // RoadPlanner,
});

function dropdownHandler(element) {
  let single = element.getElementsByTagName("ul")[0];
  single.classList.toggle("hidden");
}

function MenuHandler(el, val) {
  let MainList = el.parentElement.parentElement.getElementsByTagName("ul")[0];
  let closeIcon = el.parentElement.parentElement.getElementsByClassName("close-m-menu")[0];
  let showIcon = el.parentElement.parentElement.getElementsByClassName("show-m-menu")[0];
  if (val) {
    MainList.classList.remove("hidden");
    el.classList.add("hidden");
    closeIcon.classList.remove("hidden");
  } else {
    showIcon.classList.remove("hidden");
    MainList.classList.add("hidden");
    el.classList.add("hidden");
  }
}

function searchHandler(element) {
  let Input = element.parentElement.getElementsByTagName("input")[0];
  Input.classList.toggle("w-0");
}

let sideBar = document.getElementById("mobile-nav");
let menu = document.getElementById("menu");
let cross = document.getElementById("cross");
const sidebarHandler = (check) => {
  if (check) {
    sideBar.style.transform = "translateX(0px)";
    menu.classList.add("hidden");
    cross.classList.remove("hidden");
  } else {
    sideBar.style.transform = "translateX(-100%)";
    menu.classList.remove("hidden");
    cross.classList.add("hidden");
  }
}

function newPrepForm(url) {
  console.log('x');
  return {
    formData: {
      name: '',
      quantity: 0
    },
    message: '',
    loading: false,
    buttonLabel: 'Zapisuję',

    submitForm() {
      // console.log('-------', this.$refs.newPrepFormRef, this.formData);
      this.message = ''
      this.loading = false
      this.buttonLabel = 'Zapisuję'
      fetch(url, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          ...this.formData,
          authenticity_token: this.$refs.newPrepForm.elements['authenticity_token']?.value
        })
      })
        .then(() => {
          this.message = 'Zapisałem poprawnie';
        })
        .catch(() => {
          this.message = 'Ups. Coś poszło nie tak ;/';
        })
        .finally(() => {
          this.loading = false;
          this.buttonLabel = 'Zapisz';
        })
    }
  }
}

window.dropdownHandler = dropdownHandler;
window.MenuHandler = MenuHandler;
window.searchHandler = searchHandler;
window.sidebarHandler = sidebarHandler;
window.Alpine = Alpine
window.newPrepForm = newPrepForm;
window.summarizeCheckboxes = summarizeCheckboxes;
if (typeof window !== 'undefined') {
  window.addEventListener('DOMContentLoaded', () => {
    window.Alpine.start()
  })
}


