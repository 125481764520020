export default class TablePosition {
  constructor(container) {
    this.container = container;
    this.sectorElem = $(this.container);
    this.sectorOffset = this.sectorElem.offset();
    console.log(this.sectorOffset);
    // this.sectorBoundaries = {
    //   left: this.sectorOffset.left,
    //   right: this.sectorOffset.left + this.sectorElem.width(),
    //   top: this.sectorOffset.top,
    //   bottom: this.sectorOffset.top + this.sectorElem.height(),
    // };
    this.startPosition = {}
  }

  setStartCoordinates(event, pointer) {
    this.startPosition = {
      x: Math.floor(pointer.pageX - this.sectorOffset.left - pointer.layerX),
      y: Math.floor(pointer.pageY - this.sectorOffset.top - pointer.layerY)
    };
  }

  saveCoordinates(event, pointer) {
    var tablePosition = {
      x: Math.floor(pointer.pageX - this.sectorOffset.left - pointer.layerX),
      y: Math.floor(pointer.pageY - this.sectorOffset.top - pointer.layerY),
    };
    console.log(pointer, this.sectorOffset);
    console.log(tablePosition, pointer.pageX, this.sectorOffset.left, pointer.layerX);
    console.log(tablePosition, pointer.pageY, this.sectorOffset.top, pointer.layerY);

    if (tablePosition.x < 0 || tablePosition.y < 0) {
      pointer.draggabilly('setPosition', this.startPosition.x, this.startPosition.y);
    } else {
      // ratio = ratio < 1 ? 1+ratio : ratio;
      let ratio = 1;
      const id = pointer.path[2].dataset.id
      $.ajax({
        url: '/admin/tables/' + id,
        type: 'PUT',
        data: {restaurant_table: {position_x: tablePosition.x*ratio, position_y: tablePosition.y*ratio}}
      });
      console.log(ratio, tablePosition);
    }
  }
}
