var ratio = 1; // variable used in table sectors (mobile view vs desktop)
const csrf_token = () => $('meta[name="csrf-token"]').attr('content');
// window.$ = window.jQuery = $;

function submitForm() {
  if ($('.upload-form').length) {
    $('.upload-form')
        .submit(function (e) {
          e.preventDefault();
          $.ajax({
            url: $(this).attr('action'),
            type: 'POST',
            data: new FormData(this),
            processData: false,
            contentType: false
          });
        });
  }
}

function resetAmount(e) {
  e.preventDefault();
  $('.amount').each(function (i) {
    $(this).val(0)
  })
}

function setDefaultAmount() {
  $('.amount').click(function (e) {
    if ($(this).val() == 0) {
      $(this).val($(this).data('amount'));
    }
  })
}

function uploadPayroll() {
  if ($('.upload-payroll').length) {
    $('.upload-payroll').change(function () {
      $(this).closest('form').submit();
    })
  }
}

function niceInputFile() {
  if ($('.id-input-file').length) {
    $('.id-input-file').ace_file_input({
      no_file: 'Brak wybranych plików',
      btn_choose: 'wybierz',
      btn_change: 'zmień',
      droppable: false,
      thumbnail: false //| true | large
      //whitelist: ['csv']
    });
  }
}
function setDisabled() {
  if ($('.set_disabled').length) {
    $('.set_disabled').click(function () {
      e = $(this);
      e.addClass('disabled');
      setTimeout("e.removeClass('disabled');", 5000);
    });
  }
}

//function setFlagForPicture() {
//  if ($('.set-flag').length) {
//    $('.set-flag').click(function(e){
//      e.preventDefault();
//      uri =
//      $.ajax({
//        url:
//      })
//    })
//  }
//}

function convertCordinates(elem) {
  elem.keyup(function () {
    $(this).val($(this).val().replace(/[,\s]/, '.'));
  });
}

function addProductId(suggestion) {
  $('#' + $(this).data('product_id')).val(suggestion.data)
}

function productsSearch() {
  return {
    serviceUrl: '/admin/products/search',
    dataType: 'json',
    minChars: 4,
    onSelect: addProductId
  }
}

function suppliersSearch() {
  return {
    serviceUrl: '/admin/products/suppliers',
    dataType: 'json',
    minChars: 3,
  }
}

function autoComplete() {
  if ($('.autocmp').length) {
    $('.autocmp.products').autocomplete(productsSearch());
    $('.autocmp.suppliers').autocomplete(suppliersSearch());
  }
}

function toggleMenu() {
  $('#menu-toggler').click(function () {
    el = $('#sidebar');
    if ($(this).hasClass('display') && !el.hasClass('display')) {
      el.addClass('display');
    } else if (!$(this).hasClass('display') && el.hasClass('display')) {
      el.removeClass('display');
    }
  })
}

function plusMinusInput() {
  $('.plus-minus').off('click', '.btn-number');
  $('.plus-minus').on('click', '.btn-number', function (e) {
    e.preventDefault();

    fieldName = $(this).attr('data-field');
    type = $(this).attr('data-type');
    // var input = $("input[name='" + fieldName + "']");
    var input = $(`#${fieldName}`);
    var currentVal = parseInt(input.val());
    if (!isNaN(currentVal)) {
      if (type == 'minus') {

        if (currentVal > input.attr('min')) {
          input.val(currentVal - 1).change();
        }
        if (parseInt(input.val()) == input.attr('min')) {
          $(this).attr('disabled', true);
        }

      } else if (type == 'plus') {

        if (currentVal < input.attr('max')) {
          input.val(currentVal + 1).change();
        }
        if (parseInt(input.val()) == input.attr('max')) {
          $(this).attr('disabled', true);
        }

      }
    } else {
      input.val(0);
    }
  });
  $('.input-number').focusin(function () {
    $(this).data('oldValue', $(this).val());
  });
  $('.input-number').click(function () {
    $(this).select();
  });
  $('.input-number').change(function () {

    minValue = parseInt($(this).attr('min'));
    maxValue = parseInt($(this).attr('max'));
    valueCurrent = parseInt($(this).val());

    name = $(this).attr('name');
    if (valueCurrent >= minValue) {
      $(".btn-number[data-type='minus'][data-field='" + name + "']").removeAttr('disabled')
    } else {
      alert('Sorry, the minimum value was reached');
      $(this).val($(this).data('oldValue'));
    }
    if (valueCurrent <= maxValue) {
      $(".btn-number[data-type='plus'][data-field='" + name + "']").removeAttr('disabled')
    } else {
      alert('Sorry, the maximum value was reached');
      $(this).val($(this).data('oldValue'));
    }
    if ($(this).data('record')) {
      updateTotalQuantity($(this).data('product'), $(this).val())
    }
  });

  $(".input-number").keydown(function (e) {
    // Allow: backspace, delete, tab, escape, enter and .
    if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 190]) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode == 65 && e.ctrlKey === true) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
  });
}
window.plusMinusInput = plusMinusInput

function updateTotalQuantity(product_id, quantity) {
  $.ajax({
    url: '/admin/products/'+product_id+'/update_total_quantity',
    type: 'PUT',
    data: "total_quantity="+quantity,
    beforeSend: function (xhr) {
      xhr.setRequestHeader("X-CSRF-Token", csrf_token());
    },
    success: function(data) {
      console.log(data);
    }
  });
}

function handleAvailability() {
  $('#menu-meals').off('change', '.availability').on('change', '.availability', function() {
    updateMealRequest($(this).data('meal'), 'is_available', $(this).prop('checked'))
  })
}

function handleDivision() {
  $('#menu-meals').off('change', '.division-select').on('change', '.division-select', function() {
    updateMealRequest($(this).data('meal'), 'division_id', $(this).val())
  })
}

function updateMealRequest(meal_id, field_name, value) {
  $.ajax({
    url: '/admin/restaurants/1/meals/'+meal_id,
    type: 'PUT',
    data: "meal["+ field_name +"]="+value,
    beforeSend: function (xhr) {
      xhr.setRequestHeader("X-CSRF-Token", csrf_token());
    },
    success: function(data) {
      console.log(data);
    }
  });
}

function handleCateringMeals(table) {
  $('#chosen-meals').on('change', '.meal-checkbox', function() {
    var tr1 = $(this).closest('tr');
    table.row.add(tr1).draw();
    // id = '#catering-meals #meal_'+ $(this).attr('id');
    // $(id).prop('checked', false);
  });

  $('#catering-meals').on('change', '.meal-checkbox', function() {
    var tr = $(this).parents('tr');
    $('#chosen-meals').append(tr.clone());
    table.row(tr).remove().draw();

    // if ($(this).prop('checked')) {
    //   list.push({
    //     meal: $(this).data('meal'),
    //     kcal: $(this).data('kcal'),
    //     category: $(this).data('category')
    //   })
    // } else {
    //   // var index = array.indexOf(item);
    //   // if (index !== -1) array.splice(index, 1);
    // }
    // console.log(list);
    // list.forEach(function(o) {
    //   str = '<li>' + o.meal + '</li>';
    // })
    // $('#chosen-meals').append(str)
  })
}

$('.btn-number').on('click push', function(e){
  e.preventDefault();
  fieldName = $(this).attr('data-field');
  type      = $(this).attr('data-type');
  var input = $("input[name='"+fieldName+"']");
  var currentVal = parseInt(input.val());
  if (!isNaN(currentVal)) {
    if(type == 'minus') {
      if(currentVal > input.attr('min')) {
        input.val(currentVal - 1).change();
      }
      if(parseInt(input.val()) == input.attr('min')) {
        $(this).attr('disabled', true);
      }
    } else if(type == 'plus') {
      if(currentVal < input.attr('max')) {
        input.val(currentVal + 1).change();
      }
      if(parseInt(input.val()) == input.attr('max')) {
        $(this).attr('disabled', true);
      }
    }
  } else {
    input.val(0);
  }
});

$('.input-number').focusin(function() {
  $(this).data('oldValue', $(this).val());
});
$('.input-number').change(function() {

  minValue =  parseInt($(this).attr('min'));
  maxValue =  parseInt($(this).attr('max'));
  valueCurrent = parseInt($(this).val());

  name = $(this).attr('name');
  if(valueCurrent >= minValue) {
    $(".btn-number[data-type='minus'][data-field='"+name+"']").removeAttr('disabled')
  } else {
    $(this).val($(this).data('oldValue'));
  }
  if(valueCurrent <= maxValue) {
    $(".btn-number[data-type='plus'][data-field='"+name+"']").removeAttr('disabled')
  } else {
    $(this).val($(this).data('oldValue'));
  }


});
$('.input-number')
    .click(function () {
      $(this).select();
    })
    .keydown(function (e) {
  // Allow: backspace, delete, tab, escape, enter and .
  if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 190]) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode == 65 && e.ctrlKey === true) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {
    // let it happen, don't do anything
    return;
  }
  // Ensure that it is a number and stop the keypress
  if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
    e.preventDefault();
  }
});

function attrId() {
  return '#' + $(this).attr('id');
}

function loadOnLoad() {
  $('#send_form_btn').click(function () {
    $('.container .simple_form').submit()
  });

  // $('.datepicker')
  //   .attr('autocomplete', 'off')
  //   .attr('data-target', attrId)
  //   .attr('data-toggle', 'datetimepicker')
  //   .datetimepicker({
  //   format: "L",
  //   locale: "pl",
  //   autoclose: true,
  //   todayHighlight: true
  // });


  $('input[type="number"]').click(function () {
    $(this).select();
  });

  // $("#sidebar-wrapper").mCustomScrollbar({
  //   theme: "minimal"
  // });
  $('#dismiss, .overlay').on('click', function () {
    $('#sidebar').removeClass('active');
    $('.overlay').removeClass('active');
  });
  $('#sidebarCollapse').on('click', function(e) {
    console.log(e);
    if ($('#sidebar').hasClass('active')) {
      $('#sidebar').removeClass('active');
      $('.overlay').removeClass('active');
    } else {
      $('#sidebar').addClass('active');
      $('.overlay').addClass('active');
      $('.collapse.in').toggleClass('in');
      $('a[aria-expanded=true]').attr('aria-expanded', 'false');
    }
  });
  plusMinusInput();
  autoComplete();
  $('#search-meal').click((e) => e.target.select());
  return;
  // $('.formtastic').valtastic();
  // $('.summernote').summernote();

  handleAvailability();
  handleDivision();
  //niceInputFile();
  //submitForm();
  //uploadPayroll();
  //setDisabled();
  // $(document).on('turbolinks:click', function (event) {
  //   if (event.target.getAttribute('href').charAt(0) === '#') {
  //     return event.preventDefault();
  //   }
  // });
  if ($('.amount').length) {
    setDefaultAmount();
  }
  if ($('#longitude').length) {
    convertCordinates($('#longitude'));
    convertCordinates($('#latitude'));
  }
  $('#reset_amount').click(function (event) {
    resetAmount(event)
  });

  toggleMenu();
}

function roundNum(num) {
  return Math.round( ( num + Number.EPSILON ) * 100 ) / 100
}
window.roundNum = roundNum;

$(function () {
  loadOnLoad();
  // $(document).on('ready, turbolinks:load', function () {
  //   loadOnLoad();
  // });
});



// $(document).on('ready', function () {
//   alert('xxx');
   //$colorboxOverlay.appendTo('body');
   //$colorboxBox.appendTo('body');
 //loadOnLoad();
// });
